/**
 * Posts
 * -----
 */

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Sitewidth from '../components/Sitewidth';
import Post from '../components/Post';

const Posts = () => (
  <StaticQuery
    query={graphql`
      query PostsQuery {
        allMarkdownRemark(
          limit: 3
          filter: {fileAbsolutePath: {regex: "/posts/"}}
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          edges {
            node {
              id
              excerpt(pruneLength: 1000)
              frontmatter {
                title
                tags
                link {
                  text
                  alt
                  href
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Sitewidth>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <Post
            key={node.id}
            title={node.frontmatter.title}
            content={node.excerpt}
            tags={node.frontmatter.tags}
            link={node.frontmatter.link}
          />
        ))}
      </Sitewidth>
    )}
  />
);

export default Posts;
