import React, { Component } from 'react';
import { StaticQuery, graphql, Link as GatsbyLink } from 'gatsby';
import { isMobile } from 'react-device-detect';

import { ThemeProvider } from 'styled-components';

import Block from '../components/Block';
import Sitewidth from '../components/Sitewidth';
import Text from '../components/Text';
import Link from '../components/Link';
import Feature from '../components/Feature';

const punsList = ['On Wikipedia 🤓', 'Also on Wikipedia 😅', 'Take a guess 😂'];

class Features extends Component {
  constructor(props) {
    super(props);

    this.state = {
      index: 0,
      puns: [' ', ' ', ' '],
    };
  }

  componentDidMount() {
    if (isMobile) { this.setMobilePuns(); }
  }

  setMobilePuns = () => {
    this.setState({
      puns: punsList,
    });
  }

  updatePuns = (pun) => {
    if (this.state.puns[pun] === ' ') {
      const puns = this.state.puns;
      puns[pun] = punsList[this.state.index];

      this.setState(prevState => ({
        index: Math.min(prevState.index + 1, 2),
        puns,
      }));
    }
  }

  render() {
    return (
      <Block
        flexDirection={['column', 'column', 'row']}
        justifyContent="space-between"
        flexWrap={['nowrap', 'nowrap', 'nowrap', 'wrap']}
        ml={[0, 0, 0, 0, 8]}
        mt={[7, 7, 7, 7, 0]}
      >
        <Feature
          onMouseEnter={() => this.updatePuns(0)}
          href="https://en.wikipedia.org/wiki/Web_development"
          title="Web"
          pun={this.state.puns[0]}
          transform="translate(10%, -30%)"
        >
          From interactive websites, over highly custom CMS platforms, to
          sophisticated web apps and services done exclusively in MERN or JAM
          stack, depending on scope and complexity.
        </Feature>
        <Feature
          onMouseEnter={() => this.updatePuns(1)}
          href="https://en.wikipedia.org/wiki/Mobile_app_development"
          title="Mobile"
          pun={this.state.puns[1]}
          transform="translate(20%, 15%)"
        >
          Mobile app development based on React Native with a combination
          of native modules and bindings in Swift and Java.
        </Feature>
        <Feature
          onMouseEnter={() => this.updatePuns(2)}
          href="https://en.wikipedia.org/wiki/Internet_of_things"
          title="IoT"
          pun={this.state.puns[2]}
          transform="translate(40%, -30%)"
        >
          Network of physical devices powered by our very own hiveOS™ Linux
          Distribution, running diverse ecosystem of applications for various
          occasions.
        </Feature>
      </Block>
    );
  }
}

const About = () => (
  <StaticQuery
    query={graphql`
      query CareersNumberQuery {
        careers: allMarkdownRemark(
          filter: {fileAbsolutePath: {regex: "/careers/"}}
        ) { totalCount } 
      }
    `}
    render={({ careers }) => (
      <ThemeProvider theme={{ mode: 'light' }}>
        <Sitewidth>
          <Block
            mt={[5, 6, 7]}
            flexDirection={['column', null, null, null, 'row']}
          >

            <Block maxWidth={[null, null, '380px']}>
              <Text is="h2" fontSize={[7, 8]} mb={[3, 4]} fontWeight="bold">
                Let’s build something amazing together.
              </Text>
              <Text is="p" fontSize={[2, 3]} mb={5} fontWeight="light">
                {`We like to think of our team as ever-growing. Therefore,
                you'll find different positions open at all times. At the
                moment there are ${careers.totalCount}, some of which have
                higher number of seats
                available. We tend to never close our openings and instead
                consider people when we believe someone has what it takes,
                and most importantly belongs.`}
              </Text>
              <Link
                is={GatsbyLink}
                to="/careers/"
                alt={`${careers.totalCount} Open Positions`}
                fontSize={[2, 3]}
              >Apply
              </Link>
            </Block>

            <Features />

          </Block>
        </Sitewidth>
      </ThemeProvider>
    )}
  />
);

export default About;
