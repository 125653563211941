/**
 * Quotes
 * ------
 */

import React, { Component } from 'react';
import { ViewPager, Frame, Track, View } from 'react-view-pager';

import styled, { css } from 'styled-components';

import Block from './Block';
import Text from './Text';

const StyledFrame = styled(Frame)`
  outline: none !important;
  
  cursor: grab;
  &:active { cursor: grabbing !important; }
  *:active { cursor: grabbing !important; }
`;

const StyledView = styled(View)`
  opacity: 0.2;
  transition: opacity 500ms;

  ${props => props['data-active'] && css`
    opacity: 1;
  `}
`;

class QuotesSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentView: 1,
      viewsToShow: 1.2,
    };
  }

  componentDidMount = () => {
    this.updateViewsToShow();
    window.addEventListener('resize', this.updateViewsToShow);
  }

  updateCurrentView = (...args) => {
    this.setState({
      currentView: (args[0][0]),
    });
  }

  updateViewsToShow = () => {
    const vw = window.innerWidth;
    let viewsToShow;

    switch (true) {
      case vw >= 1920:
        viewsToShow = 3;
        break;
      case vw >= 1600:
        viewsToShow = 2.5;
        break;
      case vw >= 1280:
        viewsToShow = 2;
        break;
      case vw >= 1024:
        viewsToShow = 1.5;
        break;
      default:
        viewsToShow = 1.2;
    }

    this.setState({
      viewsToShow,
    });
  }

  render() {
    return (
      <ViewPager>
        <StyledFrame>
          <Track
            align={0.5}
            currentView={this.state.currentView}
            viewsToShow={this.state.viewsToShow}
            onViewChange={this.updateCurrentView}
          >
            {this.props.quotes.map((quote, i) => (
              <StyledView
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                data-active={i === this.state.currentView ? true : false}
              >
                <Block alignItems="center" mb={[5, 7]} px={[4, 5, 6]}>
                  <Text is="h3" textAlign="center" fontSize={[2, 3, 4]}>
                    {quote.text}
                  </Text>
                  <Text
                    is="p"
                    textAlign="center"
                    fontSize={[1, 2]}
                    mt={[4, 5]}
                  >
                    {quote.name}, {quote.title}
                  </Text>
                  <Text
                    is="p"
                    textAlign="center"
                    fontSize={[1, 1]}
                    style={{ opacity: 0.5 }}
                  >
                    {quote.company}
                  </Text>
                </Block>
              </StyledView>
            ))}
          </Track>
        </StyledFrame>
      </ViewPager>
    );
  }
}

export default QuotesSlider;
