/**
 * Quotes
 * ------
 */

import React, { Component } from 'react';
import Lightbox from 'react-images';

import styled, { injectGlobal } from 'styled-components';

import Parallax from '../components/Parallax';
import Mask from '../components/Mask';
import Loader from '../components/Loader';

import { noJitter, random } from '../utils';

import maskBottom from '../assets/graphics/quotes-mask-bottom.svg';
import zoom from '../assets/icons/zoom.svg';

const images = [
  {
    src: 'gallery/image-01.jpg',
    thumb: {
      src: 'gallery/thumbnail-01.png',
      size: '10%',
      position: '198.7%, -116%',
    },
  },
  {
    src: 'gallery/image-02.jpg',
    thumb: {
      src: 'gallery/thumbnail-02.png',
      size: '15.97%',
      position: '170.4%, -11.6%',
    },
  },
  {
    src: 'gallery/image-03.jpg',
    thumb: {
      src: 'gallery/thumbnail-03.png',
      size: '16.8%',
      position: '247.5%, -36%',
    },
  },
  {
    src: 'gallery/image-04.jpg',
    thumb: {
      src: 'gallery/thumbnail-04.png',
      size: '13.5%',
      position: '485.6%, -84.2%',
    },
  },
];

const Image = props => (
  <div className={props.className}>
    <div><img src={props.src} alt="" /></div>
  </div>
);

class Images extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div>
        {images.map((image, i) => image.thumb && (
          <Parallax
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            depth={random(5, 15) / 100}
            offset={random(10, 30)}
            only="negative"
          >
            {/* eslint-disable-next-line */}
            <div onClick={() => { this.props.onClick(i) }}>
              <StyledImage
                src={image.thumb.src}
                size={image.thumb.size}
                position={image.thumb.position}
              />
            </div>
          </Parallax>
        ))}
      </div>
    );
  }
}

const StyledImage = styled(Image)`
  ${noJitter};

  position: absolute;
  left: 0;
  bottom: 0;
  width: ${props => props.size};
  cursor: pointer;

  transform: translate(${props => props.position});

  &:after {
    content: "";
    position: absolute;
    pointer-events: none;
    top: 50%;
    left: 50%;
    width: 32px;
    height: 32px;
    background: url(${zoom}) no-repeat center;
    background-size: contain;

    opacity: 0;
    transform: translate(-50%, -50%) scale(1.3);
    transition: opacity 500ms, transform 300ms;
  }

  &:hover:after{
    opacity: 1;
    transform:  translate(-50%, -50%) scale(1);
  }

  div {
    transition: opacity 300ms;
    &:hover {
      opacity: 0.5;
    }
  }

  img {
    width: 100%;
    pointer-events: none;
  }
`;

// eslint-disable-next-line
injectGlobal`
  #lightboxBackdrop {
    img {
      border-radius: 5px;
    }

    [class^='arrow'] {
      display: none;
    }

    [class^='footerCount'] {
      font-family: 'Montserrat',
      'Avenir Next', 'Helvetica Neue', 'Helvetica',
      'sans-serif';
      opacity: 0.5;
      margin: 8px;
      white-space: nowrap;
    }
  }
`;

class Gallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      currentImage: 0,
    };
  }

  openLightbox = (i) => {
    this.setState({
      isOpen: true,
      currentImage: i,
    });
  }

  prev = () => {
    this.setState(prevState => ({
      currentImage: Math.max(prevState.currentImage - 1, 0),
    }));
  }

  next = () => {
    this.setState(prevState => ({
      currentImage: Math.min(prevState.currentImage + 1, images.length - 1),
    }));
  }

  chooseDirection = (event) => {
    if (event.nativeEvent.clientX > (window.innerWidth / 2)) {
      this.next();
    } else {
      this.prev();
    }
  }

  close = () => {
    this.setState({
      isOpen: false,
      currentImage: 0,
    });
  }

  render() {
    return (
      <Mask bottom src={maskBottom}>
        <Images onClick={this.openLightbox} />
        <Lightbox
          backdropClosesModal
          showCloseButton={false}
          imageCountSeparator=" / "
          width={1200}
          images={images}
          isOpen={this.state.isOpen}
          onClickPrev={this.prev}
          onClickNext={this.next}
          onClose={this.close}
          onClickImage={this.chooseDirection}
          currentImage={this.state.currentImage}
          spinner={Loader}
        />
      </Mask>
    );
  }
}

export default Gallery;
