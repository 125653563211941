/**
 * Mask
 * ------
 */


import React from 'react';

import styled from 'styled-components';
import { themeGet } from 'styled-system';

const Mask = props => (
  <div className={props.className}>
    <img className="mask" src={props.src} alt="" />
    {props.children}
  </div>
);

const StyledMask = styled(Mask)`
  position: absolute;
  height: auto;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: ${themeGet('breakpoints.1')}) {
    transform: ${props => (props.bottom ? 'translateX(-39%)' : null)};
  }

  width: 1200px;

  @media (min-width: ${themeGet('breakpoints.0')}) { width: 1600px; }
  @media (min-width: ${themeGet('breakpoints.2')}) { width: 1800px; }
  @media (min-width: ${themeGet('breakpoints.4')}) { width: 1920px; }
  @media (min-width: ${themeGet('breakpoints.5')}) { width: 2560px; }

  top: ${props => (props.top ? '-2px' : 'auto')};
  bottom: ${props => (props.bottom ? '-6px' : 'auto')};

  img {
    width: 100%;
  }
`;

export default StyledMask;
