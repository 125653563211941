import React from 'react';

import Layout from '../components/Layout';

import Header from '../structure/Header';
import About from '../structure/About';
import Quotes from '../structure/Quotes';
import Clients from '../structure/Clients';
import Posts from '../structure/Posts';
import Footer from '../structure/Footer';

const IndexPage = () => (
  <Layout>
    <Header />
    <About />
    <Quotes />
    <Clients />
    <Posts />
    <Footer />
  </Layout>
);

export default IndexPage;
