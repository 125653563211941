import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import styled, { ThemeProvider } from 'styled-components';
import { bottom, left } from 'styled-system';
import tag from 'clean-tag';

import Block from '../components/Block';
import Sitewidth from '../components/Sitewidth';
import Logo from '../components/Logo';
import Text from '../components/Text';
import Button from '../components/Button';
import Parallax from '../components/Parallax';
import Particles from '../components/Particles';

import hexagonBase from '../assets/graphics/hexagon-color-base.svg';
import hexagonMask from '../assets/graphics/hexagon-color-mask.svg';

const Hexagon = styled(tag)`
  ${bottom};
  ${left};

  position: absolute;
  pointer-events: none;
  width: 2000px;
  height: 2000px;
  background: url(${hexagonBase}) no-repeat bottom right;
  background-size: contain;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &:after {
    background: url(${hexagonMask}) no-repeat bottom;
    background-size: contain;
    z-index: 1000;
  }
`;

Hexagon.defaultProps = {
  left: ['-850px', '-780px', '-700px'],
  bottom: ['-300px', '-340px', '-280px'],
};

const Overflow = styled(Block)`
  overflow: hidden;
`;

const Header = () => (
  <ThemeProvider theme={{ mode: 'color' }}>
    <Block>
      <Particles height="200%" />
      <Overflow>
        <Sitewidth>
          <Block maxWidth="620px" pb={[9, 9, 10]}>
            <Hexagon />
            <Parallax depth={0.3} align="top">
              <Logo size="large" mt={[6, 7]} />
              <Text
                is="h1"
                fontSize={[8, 9]}
                mb={3}
                mt={[7, 8]}
                fontWeight="heavy"
              >
                Creative development hub.
              </Text>
              <Text
                is="h1"
                fontSize={[5, 6, 7, 8]}
                mb={6}
                fontWeight="regular"
              >
                We team up with enthusiasts to envision
                and realize game-changing products.
              </Text>
              <Button
                data-text="Join Us"
                is={GatsbyLink}
                to="/careers/"
              />
            </Parallax>
          </Block>
        </Sitewidth>
      </Overflow>
    </Block>
  </ThemeProvider>
);

export default Header;
