/**
 * Quotes
 * ------
 */

import React from 'react';

import styled, { ThemeProvider } from 'styled-components';

import Block from '../components/Block';
import QuotesSlider from '../components/QuotesSlider';
import Gallery from './Gallery';
import Parallax from '../components/Parallax';
import Mask from '../components/Mask';

import maskTop from '../assets/graphics/quotes-mask-top.svg';

const quotes = [
  {
    name: 'Faisal Hamidi',
    title: 'CPO',
    company: 'Saco World',
    text: `The guys at TheHive™ built us an amazing system on top of their
    hiveOS™, that allows us to seamlessly control, manage and analyze store
    content on over 800 screens across almost 40 stores. A greatly talented
    and supportive team that have a solution for everything.`,
  },
  {
    name: 'Leigh Odendaal',
    title: 'Associate Director',
    company: 'Proctor & Gamble',
    text: `TheHive™ team helped us set the world record for the Longest
    Greetings Card Mosaic as part of our 'Thank You Mom, for Raising a
    Champion' campaign. They've implemented an amazing experience for
    collecting and organizing notes that were collected by thousands of
    shoppers over the course of 10 days, that eventually ended up as a huge
    montage of cards on a billboard in Dubai, measuring an incredible 110
    metres long.`,
  },
  {
    name: 'Tamim Fares',
    title: 'Head of Consumer Marketing',
    company: 'Vodafone',
    text: `We had an amazing time working with TheHive™ team to implement an
    entire ecosystem of embedded devices used for marketing purposes across MENA
    region. Each of over 200 smartshelf devices were assembled out of 5 separate
    touchscreens working in conjunction similar to those of a video wall, but
    with touch controlled content.`,
  },
];

const Wrapper =styled(Block)`
  background: linear-gradient(to right, #111, #333);
`;

const Quotes = () => (
  <ThemeProvider theme={{ mode: 'dark' }}>
    <Wrapper
      minHeight={['840px', null, '1050px', '1050px', '1050px', '1400px']}
      mt={[6, '', '', '', 0]}
      mb={[6, '', '', 7]}
      justifyContent="center"
      pb={[8, 8, 9]}
    >
      <Mask top src={maskTop} />
      <Parallax style={{ width: '100%' }} depth={0.3}>
        <QuotesSlider quotes={quotes} />
      </Parallax>
      <Gallery />
    </Wrapper>
  </ThemeProvider>
);

export default Quotes;
