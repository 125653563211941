/**
 * Loader
 * ------
 */

import React from 'react';

import styled, { css } from 'styled-components';
import { themeGet } from 'styled-system';

import { ease } from '../utils';

const conf = {
  radius: 30,
  stroke: 4,
  speed: 750,
};

const Loader = props => (
  <div className={props.className}>
    <div className="global">
      <svg height={conf.radius * 4} width={conf.radius * 4}>
        <circle cx={conf.radius * 2} cy={conf.radius * 2} r={conf.radius} />
      </svg>
    </div>
  </div>
);

const StyledLoader = styled(Loader)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  opacity: 0;
  animation: startLoading ${conf.speed}ms forwards;
  @keyframes startLoading { 100% { opacity: 1; }}

  ${props => (props.global && css`animation-delay: ${conf.speed}ms`)};

  div {
    ${props => (props.loaded === true && css`
      animation: loaded ${conf.speed}ms forwards;
    `)};
    @keyframes loaded { 100% { opacity: 0; }}
  }

  svg {
    animation: rotate ${conf.speed}ms linear infinite;
    @keyframes rotate { 0% { transform: rotate(-360deg); }}
  }

  circle {
    fill: transparent;

    stroke-linecap: round;
    stroke-width: ${conf.stroke}px;
    stroke: ${props => (props.global
      ? themeGet('colors.brand.base')
      : 'white'
    )};

    stroke-dasharray: ${2 * conf.radius * Math.PI};
    stroke-dashoffset: ${2 * conf.radius * Math.PI * 0.66};

    animation: fillIn ${conf.speed * 5}ms ${ease('out', 'quart')};
    @keyframes fillIn { 0% { stroke-dashoffset: ${2 * conf.radius * Math.PI}; }}
  }
`;

export default StyledLoader;
